// ANGULAR
import { Component, OnInit, Inject } from '@angular/core';

// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ng-bee-mat-dialog-confirm-entry',
  templateUrl: './mat-dialog-confirm-entry.component.html',
  styleUrls: ['./mat-dialog-confirm-entry.component.scss'],
})
export class MatDialogConfirmEntryComponent implements OnInit {
  header: string;
  body = null;
  constructor(
    public matDialogRef: MatDialogRef<MatDialogConfirmEntryComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      headerTranslateKey: string;
      headerParams?: Record<string, any>;
      bodyTranslateKey: string;
      bodyParams?: Record<string, any>;
      alertType: '' | 'info' | 'warn';
      alertTranslateKey?: string;
      submitActionTranslateKey?: string;
      isCancelHidden: boolean;
    },
    public translateService: TranslateService,
  ) {}

  ngOnInit() {
    if (this.data.bodyTranslateKey) {
      this.body = this.translateService.instant(
        this.data.bodyTranslateKey,
        this.data.bodyParams || undefined,
      );
    }
  }

  closeDialog() {
    this.matDialogRef.close();
  }

  confirm() {
    this.matDialogRef.close(true);
  }

  cancel() {
    this.matDialogRef.close(false);
  }
}
