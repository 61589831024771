// ANGULAR
import { Component, inject, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

// MODELS
import { WHEntryDOM } from '@workheld/workheld-shared-lib';
import { MatDialogEntryDetailEntryComponent } from 'src/app/app-dialogs/mat-dialog-entry-detail-entry/mat-dialog-entry-detail-entry.component';

@Component({
  selector: 'ng-bee-entry-static-data',
  templateUrl: './entry-static-data.component.html',
  styleUrls: ['./entry-static-data.component.scss'],
})
export class EntryStaticDataComponent {
  #router = inject(Router);

  constructor(
    private dialogRef: MatDialogRef<MatDialogEntryDetailEntryComponent>,
  ) {}
  @Input() public entryDOM: WHEntryDOM & {
    workObjectStatusId: number;
    workObjectDetails: any;
  };

  navigateToProjectDetails() {
    const parts = this.entryDOM?.workObjectDetails?.clientUrl.split('/');
    const apiIndex = parts.findIndex((part) => part === 'api');
    const path = parts.slice(apiIndex + 1).join('/');
    this.dialogRef.close();

    this.#router.navigateByUrl(`/${path}`);
  }
}
